<script lang="ts">
    import LoggedIn from "./LoggedIn.svelte";
    import LoginButton from "./LoginButton.svelte";
    import { loginStatus } from "../../store/loginV2";
    import NewPasswordButton from "./NewPasswordButton.svelte";
    import { LoginStatus } from '../../../common/types';
</script>

{#if ($loginStatus).status === LoginStatus.LOGGED_IN}
    <LoggedIn />
{:else if [LoginStatus.NEW_PASSWORD_REQUIRED, LoginStatus.PASSWORD_CHANGE_FAILED].includes(($loginStatus).status)}
    <NewPasswordButton />
{:else }
    <LoginButton />
{/if}
