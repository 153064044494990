import type { LocalizationSettings } from './types';
import { PresentationTone } from '../../types';

const de: LocalizationSettings = {
    key: 'de',
    languageLabel: 'Deutsch',
    languageNameForGeneration: 'German',
    switchLabel: 'Spracheinstellung',
    rotatePrompt: 'Bitte drehen Sie ihr Gerät, um die Präsentation zu sehen',
    overview: {
        title: 'AI Slides',
        tagline: 'Lass künstliche Intelligenz deine Präsentationen erstellen!',
        newPresentation: 'Eigene Präsentation erstellen',
    },
    newPresentation: {
        createPresentationTitle: 'Erstelle eine Präsentation mit KI',
        presentationTitle: 'Denk dir einen Titel aus',
        toneErnest: 'ernst',
        toneFunny: 'albern',
        createButton: 'Erstellen',
        presentationStyle: 'Präsentationsstil',
        topic: 'zum Thema „{topic}“',
        backToLastPresentation: 'Zurück zu den Präsentationen',
        backToOverview: 'Zurück zur Übersicht',
        presenterTitle: 'Präsentator*in',
        clearPresenter: 'Entfernen',
        chooseMode: 'Bitte wählen.',
        chooseModeInfoMessage:
            'Wähle deinen bevorzugten Modus. Du kannst jederzeit zum einfachen Modus zurückwechseln. Probier es aus!',
        simpleMode: {
            title: 'Einfach',
            description:
                'Fahre mit deinem Thema fort, um die Präsentation mit einer allgemeinen Stimme und Optik zu erstellen.',
        },
        advancedMode: {
            title: 'Personalisiert',
            description:
                'Personalisiere deine Präsentation mit generativer KI.',
        },
        dataPrivacy: {
            title: 'Informationen zum Datenschutz.',
            text:
                'Wir verarbeiten Bilder, Töne und andere Inhalte in der EU und den USA mit strengen Sicherheitsmaßnahmen, einschließlich Verschlüsselung und Zugriffskontrollen, um sicherzustellen, dass Ihre Daten sicher sind und nicht zum Training von KI verwendet werden.\n\n' +
                'Der generierte Inhalt wird sofort nach der Erstellung gelöscht, wenn Sie den Präsentationsmodus verlassen.\n\n' +
                'Genießen Sie die neuesten Technologien{location}, während wir Ihre Sicherheit priorisieren und alle relevanten Datenschutzgesetze einhalten.\n\n' +
                'Für weitere Informationen besuchen Sie bitte {privacyUrl}.',
            simple: 'Einfacher Modus',
            accept: 'Akzeptieren & fortfahren',
            futureLab: ' in unserem Future Lab',
        },
        photo: {
            takeTitle: 'Ein Photo aufnehmen.',
            takeButton: 'Aufnehmen',
            takeError:
                'Das Bild konnte aufgrund eines technischen Fehlers leider nicht aufgenommen werden.',
            goToSimple: 'Einfache Präsentation erzeugen',
            takeInfoDialog:
                'Dein Bild wird verwendet, um einen Avatar zu erstellen, der auf deinem Aussehen basiert. Der Avatar wird der Moderator deiner Präsentation sein.',
            verifyTitle: 'Photo ok?',
            retake: 'Neues Bild aufnehmen',
            accept: 'Akzeptieren & fortfahren',
        },
        audio: {
            recordTitle: 'Stimme aufnehmen.',
            recordInfo:
                'Deine Aufnahme des folgenden Textes wird verwendet, um die Sprachausgabe der Präsentation zu erstellen. Wir empfehlen, klar und auf eine ansprechende Weise zu sprechen, um das beste Ergebnis zu erzielen.',
            recordContent:
                'Früh am Morgen erwacht die Stadt mit dem Klang belebter Straßen und fröhlichen Vögeln. Die Menschen eilen zur Arbeit, trinken Kaffee und begrüßen sich mit einem Lächeln. Im Park rennen Hunde frei herum, ihren Lieblingsspielzeugen hinterherjagend. Die Luft ist frisch, gefüllt mit dem Duft blühender Blumen und frisch gebackenem Brot aus der nahegelegenen Bäckerei. Das Leben in der Stadt pulsiert, und jede Ecke bietet eine neue Geschichte zum Entdecken.',
            recordContentVariant:
                'Das kleine Lämmchen Wolli hopst fröhlich über die Weide. Immer wieder schnuppert Wolli an den Blumen und Kräutern. Ganz besonders schön findet Wolli Sonnenblumen. Wolli würde auch gern mal an einer der Sonnenblumen knabbern, doch leider ist sie viel zu klein. Gern würde sie fliegen können, so wie die Schmetterlinge oder die Bienen. Dann könnte sie endlich eine Sonnenblume probieren. Wie die wohl schmeckt? Wolli ist ganz in Gedanken und merkt nicht, dass alle anderen Schafe schon längst weitergezogen sind. Da hört sie das Blöken ihrer Mutter und läuft so schnell sie kann zu ihr. »Hast Du wieder mit offenen Augen geträumt?«, fragt ihre Mutter. »Ja«, antwortet Wolli »von Sonnenblumen«. »Das passt zu Deinem sonnigen Gemüt«, sagt die Mutter.',
            startRecording: 'Aufnahme starten',
            finishRecording: 'Aufnahme beenden',
            infoDialog:
                'Bitte lies den Text auf dem Bildschirm laut vor. Er wird verwendet, um die Sprachausgabe deiner Präsentation zu erstellen. Beginne, wenn du bereit bist.',
            error: 'Die Aufnahme konnte aufgrund eines technischen Fehlers leider nicht durchgeführt werden.',
            verifyTitle: 'Aufnahme beendet.',
            verifyInfo:
                'Du kannst eine neue Audioaufnahme machen, falls du z.B. versehentlich die Aufnahme zu früh gestoppt hast. Mach dir darüber nicht zu viele Gedanken.',
            verifyContent:
                'Pausen oder andere kleine Störungen im Audio beeinflussen das Ergebnis nicht. Sollten jedoch größere Hintergrundgeräusche vorhanden sein, kannst du eine neue Aufnahme machen, um das bestmögliche Ergebnis zu erzielen.',
            retake: 'Neue Aufnahme starten',
            accept: 'Akzeptieren & fortfahren',
        },
        generating: {
            loading: 'Erstelle deine Präsentation',
            learnMore: 'Lerne etwas, während du wartest',
            infoDialog:
                'Während wir deine Präsentation erstellen, erfahre mehr über diese Präsentation, indem du durch die Bildschirme navigierst.',
        },
        cancel: {
            title: 'Personalisierung abbrechen?',
            backHome: 'Zurück zur Übersicht',
            simple: 'Einfache Präsentation erzeugen',
            continue: 'Personalisierung fortsetzen',
            infoDialog:
                'Vorsicht! Wenn du die Personalisierung abbrichst, gehen alle deine bisherigen Eingaben verloren.',
        },
    },
    newStory: {
        createStory: 'Was soll ich dir erzählen?',
        storyReady: 'Fertig! Soll ich vorlesen?',
        creationFailed: 'Ups, da ist etwas schief gelaufen.',
        titleLabel: 'Dein Titel',
        submitButtonText: "Los geht's",
        readOutButtonText: 'Vorlesen',
        retryButtonText: 'Nochmal probieren',
    },
    newDallicParty: {
        initialPrompt: 'Initaler Prompt',
        changePrompt: 'Veränderungs-Prompt',
        numberOfIterations: 'Anzahl der Iterationen',
    },
    presentationReady: {
        goToPresentation: 'Jetzt anschauen',
        title: 'Deine Präsentation ist fertig!',
        infoDialog: 'Schau dir die Präsentation auf dem oberen Bildschirm an.',
    },
    generationFailed: {
        headline: 'Technischer Fehler',
        timeoutHeadline: 'Zeitlimit überschritten',
        spicyHeadline:
            'Der angegebene Titel wurde als potentiell anstößig erkannt',
        retryPrompt: 'Nochmal versuchen',
        restartPrompt: 'Mit neuer Eingabe starten',
        quotaExceeded:
            'Das Limit für die Erstellung von Präsentationen wurde erreicht',
        info: 'Es ist ein technischer Fehler aufgetreten. Das kann z.B. passieren, wenn die verwendeten KI-Systeme nicht verfügbar sind. Versuche es einfach nochmal.',
        timeoutInfo:
            'Leider hat die Erstellung der Präsentation das Limit von 5 Minuten erreicht. Das kann daran liegen, dass der Server überlastet ist.',
        spicyInfo:
            'Wir moderieren automatisiert Ihre Eingabe. Das ist nötig, um die Bedingungen der verwendeten KI-Systeme einzuhalten.',
        quotaExceededInfo:
            'Bitte informieren Sie einen Mitarbeiter, dass die maximale Anzahl an Präsentationen für diesen Monat erreicht wurde.',
    },
    presentation: {
        jumpToOverview: 'Zur Übersicht',
        jumpToNext: 'Zur nächsten Präsentation',
        createYourOwn: 'Eigene Präsentation erstellen',
        confirmTitle: 'Präsentation beendet',
        confirmNext: 'Weiter zur nächsten Präsentation',
        replay: 'Noch einmal anschauen',
        confirmCreateOwn: 'Möchten Sie Ihre eigene Präsentation erstellen?',
        confirmCreateOwnAction: 'Eigene Präsentation erstellen',
        declineCreateOwnAction: 'Weiter diese Präsentation anschauen',
        cancelPrompt: 'Möchtest du wirklich abbrechen?',
        title: 'Präsentation wird abgespielt',
        finished: 'Präsentation beendet',
        answerQuestionFailed: 'Die Antwort konnte nicht erzeugt werden',
        actions: {
            cancel: 'Präsentation abbrechen',
            continue: 'Weiterschauen',
            pause: 'Pausieren',
            play: 'Abspielen',
            toOverview: 'Zurück zur Übersicht',
            replay: 'Nochmal anschauen',
            toggleSubtitlesOn: 'Untertitel einblenden',
            toggleSubtitlesOff: 'Untertitel ausblenden',
            askQuestion: 'Gedrückt halten, um eine Frage zu stellen',
        },
    },
    story: {
        childBookTitle: 'Der automatische Geschichtenerzähler',
        darkTalesTitle: 'Dark Tales',
        christmasTitle: 'Der mechanische Weihnachtsmann',
        hearAgainButtonText: 'Noch einmal anhören',
        backButtonText: 'Zurück zum Hauptmenü',
        playButtonText: 'Abspielen',
    },
    upload: {
        dragDropImage:
            'Ziehen Sie ein Bild hierher oder klicken Sie, um auszuwählen',
        dragDropAudio:
            'Ziehen Sie eine Audiodatei hierher oder klicken Sie, um auszuwählen',
        altImage: 'Porträt',
        audioNotSupported: 'Ihr Browser unterstützt kein Audio',
    },
    record: {
        removeRecording: 'Aufnahme löschen',
        startRecording: 'Aufnahme starten',
        portrait: 'Portrait-Bild',
        takeSnapshotHeader: 'Bild aufnehmen',
        takeSnapshot: 'Bild aufnehmen',
        acceptSnapshotHeader: 'Bild überprüfen',
        acceptSnapshot: 'Bild akzeptieren',
        retakeImage: 'Bild nochmal aufnehmen',
        audioRecordingHeader: 'Audio aufnehmen',
        readText:
            'Früh am Morgen erwacht die Stadt mit dem Klang belebter Straßen und fröhlichen Vögeln. Die Menschen eilen zur Arbeit, trinken Kaffee und begrüßen sich mit einem Lächeln. Im Park rennen Hunde frei herum, ihren Lieblingsspielzeugen hinterherjagend. Die Luft ist frisch, gefüllt mit dem Duft blühender Blumen und frisch gebackenem Brot aus der nahegelegenen Bäckerei. Das Leben in der Stadt pulsiert, und jede Ecke bietet eine neue Geschichte zum Entdecken.',
        startAudioRecording: 'Audioaufnahme starten',
        stopAudioRecording: 'Audioaufnahme stoppen',
        acceptAudioHeader: 'Audio überprüfen',
        acceptAudioRecording: 'Audio akzeptieren',
        retakeAudioRecording: 'Audio nochmal aufnehmen',
        cancel: 'Abbrechen',
    },
    info: {
        aiSlides: {
            title: 'Über AI Slides',
            text: `
AI Slides ist ein KI-basiertes Tool, das *die Eingabe eines Benutzers in eine vollständige Präsentation verwandelt*. Dieser Prozess umfasst mehrere KI-Technologien, die zusammenarbeiten.\n
Die Präsentationen werden von modernen KI-Systemen wie GPT-4, ElevenLabs, D-ID und Dalle-3 erstellt. Diese Systeme arbeiten zusammen, um *Texte zu generieren, Bilder zu erstellen und Gesichter zu animieren*. Das Ergebnis ist eine vollständige, ansprechende Präsentation.\n
Wenn alles fertig ist - die Audio-Tracks, die Bilder und der Folientext - werden sie alle zusammengeführt, um *eine fertige, KI-komponierte Präsentation* zu bilden. Diese kann dann in einem Webbrowser angesehen werden und bietet eine benutzerfreundliche Möglichkeit, Präsentationen schnell nur anhand eines Titels zu generieren.
            `,
        },
        gpt: {
            title: 'GPT-4',
            text: `
GPT-4, Abkürzung für Generative Pretrained Transformer 4, ist ein künstliches Intelligenzsystem, das dafür ausgelegt ist, Text zu erstellen. Dieses AI-System verwendet ein Netzwerk von Algorithmen, ähnlich wie das menschliche Gehirn ein Netzwerk von Neuronen verwendet, um einen gegebenen Text zu bewerten, zu verstehen und auf Basis eines gegebenen Auslösers zu generieren. Es tut dies, indem es Muster und Beziehungen zwischen Wörtern, Phrasen und Sätzen in einer riesigen Datenbank diverser Internet-Texte findet, auf die es trainiert wurde.

GPT-4 generiert Antworten auf einen Auslöser, indem es das Gelernte verwendet, um das nächste Wort in einem Satz zu erraten. Dies ist ähnlich wie wenn wir Menschen ein Spiel spielen würden, bei dem wir das nächste Wort in einem Satz erraten müssen. Es berechnet, was als Nächstes kommt, indem es die Wahrscheinlichkeit berechnet, mit der ein bestimmtes Wort oft nach dem letzten Wort im Auslöser oder Satz kommt.
            `,
        },
        dalle: {
            title: 'DALLE-3',
            text: `
Parallel dazu werden die **Bildbeschreibungen, die von GPT-4 bereitgestellt werden, als Anweisungen** für Dalle-3, einen **KI-Bildgenerator**, verwendet. Dalle-3 interpretiert diese Anweisungen, um geeignete und visuell auffallende Bilder für jede Folie der Präsentation zu generieren.

Das AI beginnt mit zufälligen Punkten und Formen und ändert sie langsam, bis es ein klares Bild erstellt, das der Beschreibung entspricht, die Sie angegeben haben. Dies wird als **"Diffusionsmodellierung"** bezeichnet. Dalle-3 zeigt, wie gut moderne KI den Sinn von Worten verstehen und sie in realistische Bilder umwandeln kann. Es kann für Dinge wie das Erstellen von Designs, die Erstellung von Kunstwerken und das Generieren von Bildern mit Computern oder in unserem Fall, um hochwertige und kontextuell relevante Bilder zu erstellen, die perfekt zum Inhalt der Präsentation passen.
            `,
        },
        did: {
            title: 'D-ID',
            text: `
Um ein visuelles Erzähl-Element in die Präsentation aufzunehmen, setzen wir D-ID ein, ein KI-System, das fortschrittliche Gesichts-Animations-Techniken nutzt, um **das Gesicht der Person auf dem Foto zu animieren**, damit es so aussieht, als würde sie sprechen. D-ID erfordert zwei wesentliche Komponenten:

 - **Foto eines Menschen-Gesichts:** Stellen Sie ein stilles Foto bereit, das die Person zeigt, deren Gesicht Sie animieren möchten.
 - **Sprachaufnahme:** Live-Aufnahme der Stimme des Vortragenden oder vorher aufgezeichnete Audiodatei.

D-ID generiert ein realistisches Video aus dem Foto, das die Worte aus der Aufnahme spricht. Anwendungen reichen von Bereichen wie Spiel-Charakter-Animation bis hin zu virtuellen Assistenten.
            `,
        },
        elevenlabs: {
            title: 'ElevenLabs',
            text: `
ElevenLabs ist ein künstliches Intelligenzsystem, das **menschengleiche Sprache aus geschriebenem Text generieren** kann. Es wurde auf vielen Beispielen echter menschlicher Stimmen trainiert, um die Muster und Charakteristika der Sprache zu erlernen. Es gibt zwei Möglichkeiten, ElevenLabs zu nutzen:

 - **Wählen Sie eine vorgefertigte Stimme** aus, um eine Sprache zu generieren, die wie die ausgewählte Stimme klingt, wenn sie den bereitgestellten Text liest.
 - **Stellen Sie ein eigenes Stimmprobe bereit**, um einen "Stimmklon" zu erstellen, der dann eine Sprache generieren kann, die Ihrer eigenen Stimme nachahmt. Wenn ein Stimmklon verwendet wird, ist die künstliche Sprache erstaunlich ähnlich der ursprünglichen menschlichen Stimmprobe. ElevenLabs zeigt, wie KI verwendet werden kann, um individuelle menschliche Stimmen realistisch nachzubilden.
            `,
        },
    },
    readInfo: 'Vorlesen',
    pauseReading: 'Pausieren',
    generatorMessages: {
        [PresentationTone.FUNNY]: {
            initial: 'KI startet durch – auf zur Präsentationserstellung!',
            messages: [
                'Brainstorming-Marathon: Welche Themen sind ein Hit?',
                'Detektivarbeit: Daten und Infos unter die Lupe nehmen',
                'Datenquellen-Party: Wer bringt die besten Infos?',
                'KI-Spürnase recherchiert wie ein Profi',
                'Farben und Stil – auf zur kreativen Modenschau!',
                'Tolle Layouts: Jede Folie soll glänzen!',
                'Schnörkel adé, wir schreiben fürs Auge',
                'Überschriften-Generator im Einsatz: Ordnung, Jungs und Mädels!',
                'Wer sind diese Menschen? Zielgruppenanalyse startet',
                'Grafik-Zauberei: So werden Daten zum Schmaus',
                'NLP-Textakrobatik – Sätze zum Verlieben',
                'Interaktivität und Animationen: Bühne frei!',
                'Medienzauber: Videos und Audio-Mixturen',
                'Text- und Grafiktuning: Perfektion in Arbeit!',
                'Daten-Snacks und Zusatzmaterialien zum Naschen',
                'Federleicht von Folie zu Folie hüpfen',
                'Sprache und Ton: von charmant bis amüsant',
                'Infografik-Spaß: Daten mit Augenzwinkern',
                'Statistiken-Prüfung: Fakten auf dem Silbertablett',
                'To-do-Listenquerweltein: Organisationswunder in Sicht!',
                'Call-to-Action-Wirbel: Interaktion mit Witz',
                'Bald gesellt sich Experten-Ruhm hinzu!',
                'Klick, Blitz, Foto! Bilder fürs Gedächtnis',
                'Präsentationsleben auf allen Geräten feiern!',
                'Barrierefreiheit, die Herzen öffnet',
                'Fußnoten und Literaturwürdigung: Quellen hochleben lassen!',
                'Optimierungs-KI: Magie im Maschinenlernen',
                'Benutzererfahrung: Rote Teppiche für alle!',
                'Anpassung: Vortragsstile auf dem Catwalk',
                'Feedback-Loop: Mit Freude Messer wetzen',
                'Goodies zum Mitnehmen: Handouts als Souvenir',
                'Social Media und Online: Lass die Vernetzung beginnen!',
                'Für jeden Topf ein süßes Deckelchen: Präsentationsanpassung',
                'Aktualisierungs-Maschine: Immer am Zahn der Zeit!',
                'Multi-Geräte-Akrobatik: Test am Limit',
                'Qualitätssicherung: Lachkrampf-Check inklusive',
                'Einsatzbereitschaft: Startschuss zur Präsentations-Parade',
                'Effektivitätssteigerung: Mit KI-Turbo!',
                'Lernfreude: Neues Wissen kommt rasant!',
                'Anpassung und Skalierung: Präsentation in Übergröße',
                'Sprachwirbel: Übersetzung in alle Ecken!',
                'Dateiformat-Reigen: Ein Format für jeden Anlass',
                'Sprachassistenten und Chatbots: Plaudertaschen im Team',
                'Fort Knox des Präsentationsschutzes',
                'Datenverlust? Nicht mit uns!',
                'Präsentations-Quantensprung durch KI und Kreativität',
                'Expertenvalidierung als Sahnehäubchen',
                'Aktualisierung mit Schwung und Feedback-Nektar',
                'Vorhang auf für die KI-generierte Präsentation – viel Vergnügen!',
            ],
        },
        [PresentationTone.ERNEST]: {
            initial:
                'Künstliche Intelligenz initialisiert, um Präsentation zu erstellen.',
            messages: [
                'Brainstorming-Prozess zur Themenfindung startet.',
                'Analyse relevanter Informationen und Daten.',
                'Verbindung zu Datenquellen hergestellt.',
                'Automatisierte Recherche zum Thema erfolgt.',
                'Entwerfen eines Farbschemas und visuellen Stils.',
                'Erstellung von Layouts für individuelle Folien.',
                'Textformatierung und Schriftauswahl optimiert.',
                'Überschriften und Zwischenüberschriften generiert.',
                'Zielgruppenanalyse durchgeführt.',
                'Anfertigung von Diagrammen und Grafiken.',
                'Erstellung verständlicher Sätze mittels NLP.',
                'Integration interaktiver Elemente und Animationen.',
                'Verknüpfung von Medien und Videos.',
                'Optimierung von Text und Grafiken.',
                'Generierung von Datenblättern und Zusatzmaterialien.',
                'Erstellung von Übergangselementen zwischen Folien.',
                'Anpassung von Sprache und Ton an Zielgruppe.',
                'Entwurf von Infografiken zur Informationsvermittlung.',
                'Überprüfung der Statistiken und Quellenangaben.',
                'Einfügen von To-do-Listen und Zusammenfassungen.',
                'Hinzufügen von Call-to-action-Elementen.',
                'Integration von Expertenmeinungen und Case-Studies.',
                'Einfügen von Fotos und Bildern.',
                'Anpassung der Präsentation an unterschiedliche Geräte.',
                'Implementierung von Barrierefreiheitsfunktionen.',
                'Fußnoten und Literaturangaben eingefügt.',
                'Anwendung von Machine Learning zur Optimierung.',
                'Evaluation der Benutzererfahrung.',
                'Anpassung an verschiedene Vortragsstile.',
                'Integration von Feedback-Funktionen.',
                'Erstellung von Ergänzungsmaterialien und Handouts.',
                'Einbindung von Social-Media- und Onlineplattformen.',
                'Personalisierung der Präsentation für Zielgruppen.',
                'Automatische Aktualisierung der Präsentation.',
                'Test auf verschiedenen Geräten und Software.',
                'Qualitätssicherung und Korrekturlesen.',
                'Funktionsumfang und Einsatzbereitschaft überprüft.',
                'Anwendung von KI-gestützter Effektivitätssteigerung.',
                'Kontinuierliches Lernen neuer Technologien und Methoden.',
                'Kapazitäten zur Anpassung und Skalierung geschaffen.',
                'Automatische Übersetzung in verschiedene Sprachen.',
                'Speicherung in verschiedenen Dateiformaten.',
                'Integration von Sprachassistenten und Chatbots.',
                'Schutz der Präsentation vor unbefugtem Zugriff.',
                'Sicherung der Präsentation gegen Datenverlust.',
                'Integration kreativer Ansätze und KI-generierter Inhalte.',
                'Validierung der Präsentation durch Experten.',
                'Aktualisierung basierend auf Feedback und Erkenntnissen.',
                'Abschluss des KI-generierten Präsentation und Vorbereitung zur Vorführung.',
            ],
        },
    },
    keyboardLayout: {
        default: [
            '^ 1 2 3 4 5 6 7 8 9 0 \u00DF \u00B4 {bksp}',
            'q w e r t z u i o p \u00FC !',
            'a s d f g h j k l \u00F6 \u00E4 ?',
            '{shift} < y x c v b n m , . - {shift}',
            '@ {space}',
        ],
        shift: [
            '\u00B0 ! " \u00A7 $ % & / ( ) = ? ` {bksp}',
            'Q W E R T Z U I O P \u00DC !',
            'A S D F G H J K L \u00D6 \u00C4 ?',
            '{shift} > Y X C V B N M ; : _ {shift}',
            '@ {space}',
        ],
    },
    keyboardLabels: {
        '{bksp}': '⌫',
        '{shift}': '⇧ Umschalt',
        '{space}': 'Leerzeichen',
    },
    landingpage: {
        login: {
            login: 'Einloggen',
            logout: 'Ausloggen',
            username: 'Accountname',
            password: 'Passwort',
            changePassword: 'Passwort ändern',
            newPassword: 'Neues Passwort',
            repeatNewPassword: 'Wiederholen',
            close: 'Schließen',
        },
        menu: {
            home: 'Startseite',
            suggestTopic: 'Thema vorschlagen',
            reviewSuggestions: 'Vorschläge ansehen',
            newPresentation: 'Neue Präsentation',
            newStory: 'Neue Geschichte',
            newDarkTale: 'Neues Dark Tale',
            newChristmasTale: 'Neue Weihnachtsgeschichte',
            newDallicParty: 'Neue Dallic Party',
        },
        footer: {
            imprint: 'Impressum',
            privacy: 'Datenschutz',
            language: 'Sprache ändern',
        },
        presentations: {
            favorite: 'Favorisieren',
            unfavorite: 'Aus Favoriten entfernen',
            delete: 'Löschen',
            publish: 'Veröffentlichen',
            unpublish: 'Depublizieren',
            failedToDelete: 'Löschen der Präsentation fehlgeschlagen',
            failedToUnpublish: 'Depublizieren der Präsentation fehlgeschlagen',
        },
        suggestTopic: {
            title: 'Vorschlag für ein Präsentationsthema',
            yourSuggestion: 'Dein Vorschlag',
            submitSuggestion: 'Vorschlag abschicken',
            suggestionSubmitted: 'Der Vorschlag wurde abgeschickt',
            suggestAnother: 'Noch einen Vorschlag machen',
            submissionFailed: 'Das hat leider nicht funktioniert',
            retry: 'Nochmal versuchen',
        },
        manageSuggestions: {
            title: 'Vorschläge verwalten',
            delete: 'Vorschlag löschen',
        },
        newPresentation: {
            title: 'Neue Präsentation',
            topic: 'Thema',
            generateTopic: 'Generiere ein Thema',
            useSuggestion: 'Verwende einen Vorschlag',
            numberOfSlides: 'Anzahl der Folien',
            narrationStyle: 'Erzählstil',
            personality: 'Persönlichkeit',
            customPersonality: 'Eigene Persönlichkeit',
            yourPersonality: 'Du',
            showPersonData: 'Zeige Persönlichkeitsdaten',
            hidePersonData: 'Verstecke Persönlichkeitsdaten',
            person: {
                name: 'Name',
                description: 'Beschreibung',
                speaker: 'Sprecher',
                imageDescription: 'Bildbeschreibung',
                fillBlanks: 'Fülle die Lücken',
                clear: 'Daten leeren',
                recording: 'Aufnahme',
                noRecording: 'Noch keine Aufnahme',
            },
            imageGenerator: 'Bildgenerator',
            videoGenerator: 'Videogenerator',
            textGenerator: 'Textgenerator',
            coverSlideVideo: 'Video für Titelfolie',
            createCoverSlideVideo: 'Erstelle Video für Titelfolie',
            subtitles: 'Untertitel',
            createSubtitles: 'Untertitel erstellen',
            content: 'Inhalt',
            moderateContent: 'Moderiere den Inhalt',
            promotion: 'Branding',
            noPromotion: 'Ohne Branding',
            createPresentation: 'Erzeuge Präsentation',
        },
    },
    kiosk: {
        dismissInfoButton: 'Ok, verstanden',
        overviewInfoMessage:
            'Schau dir eine Präsentation an oder erstelle deine eigene Präsentation.',
        createPresentationInfoMessage:
            'Beginne mit einer Eingabe. Die Eingabe wird sowohl das Thema als auch der Titel deiner Präsentation.',
        languageChoice: 'Bitte wähle deine bevorzugte Sprache.',
        impress: {
            title: 'Über dieses Exponat',
            infoText:
                'AI Slides ist ein Programm von TNG Technology Consulting GmbH. Es verwendet künstliche Intelligenz, um aus einer Benutzereingabe eine komplette Präsentation zu erstellen. Dafür arbeiten viele verschiedene KI-Technologien zusammen.\n' +
                '\n' +
                'Ursprünglich war es eine Ausstellung, die im Deutschen Museum in Bonn gezeigt wurde. Für das FutureLab wurde das Programm in Zusammenarbeit zwischen TNG und FG-160 angepasst und weiterentwickelt.',
            contact: {
                title: 'Kontakt zu den Experten aufnehmen',
                internal: 'Intern',
                external: 'Extern',
            },
            footer: 'Das Future Lab ist ein Digital Innovation Projekt',
        },
        infoFooter:
            'AI Slides was developed by TNG Technology Consulting GmbH in cooperation with BMW',
        qna: {
            title: 'Stelle eine Frage zur Präsentation',
            startRecording: 'Aufnahme starten',
            stopRecording: 'Aufnahme stoppen',
            error: 'Die Frage kann leider nicht beantwortet werden',
        },
    },
};
export default de;
