<script lang="ts">
    import { loginStatus, setCredentials } from '../../store/loginV2';
    import { derived } from 'svelte/store';
    import { LoginStatus } from '../../../common/types';
    import { _ } from 'svelte-i18n';

    let modalElement: HTMLDialogElement;
    let username: string = '';
    let password: string = '';
    $: haveUserInput = Boolean(username) && Boolean(password);

    const errorMessage = derived(loginStatus, (loginStatus) => {
        if (
            loginStatus.status === LoginStatus.LOGGED_OUT &&
            loginStatus.error
        ) {
            console.error(loginStatus.error);
            return 'Login failed!';
        }
        return undefined;
    });

    function showLoginModal() {
        modalElement?.showModal();
    }

    function closeLoginModal(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        modalElement?.close();
        username = '';
        password = '';
    }

    function logIn(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        setCredentials.next({
            username,
            password,
        });
    }
</script>

<button on:click={showLoginModal} class="button btn-primary"
    >{$_('landingpage.login.login')}</button
>

<dialog class="login-modal" bind:this={modalElement}>
    {#if $errorMessage}
        <div class="error">{$errorMessage}</div>
    {/if}
    <form>
        <span class="inputs">
            <label class="label" for="username"
                >{$_('landingpage.login.username')}</label
            >
            <input
                class="input"
                type="text"
                id="username"
                bind:value={username}
                required
            />
            <label class="label" for="password"
                >{$_('landingpage.login.password')}</label
            >
            <input
                class="input"
                type="password"
                id="password"
                bind:value={password}
                required
            />
        </span>
        <span class="actions">
            <input
                on:click={closeLoginModal}
                class="button"
                value={$_('landingpage.login.close')}
                type="button"
            />
            <button
                on:click={logIn}
                type="submit"
                disabled={!haveUserInput}
                class="button"
                on:keydown={logIn}>{$_('landingpage.login.login')}</button
            >
        </span>
    </form>
</dialog>

<style>
    .error {
        color: #f82;
        padding: 0;
        text-align: center;
        font-weight: 600;
    }

    .button {
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 1px solid var(--primary-background);
        color: var(--primary-background);
        border-radius: 0.25rem;
        background-color: var(--bs-dark);
        cursor: pointer;
    }

    .button:hover {
        color: var(--bs-dark);
        background-color: var(--primary-background);
        box-shadow: 0 0 1rem rgba(255, 255, 255, 0.2);
        border: 1px solid var(--bs-dark);
    }

    .button[disabled],
    .button[disabled]:hover {
        cursor: auto;
        background-color: #eee;
        color: #aaa;
        border-color: #eee;
    }

    .actions {
        display: flex;
        flex-direction: row;
        padding-top: 1rem;
        width: 100%;
        justify-content: stretch;
        gap: 1rem;
    }

    .actions .button {
        flex-grow: 1;
        flex-shrink: 0;
    }

    .inputs {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 0.5rem;
    }

    .inputs .label {
        text-align: center;
    }

    .inputs .input {
        min-width: 0;
        min-width: 0;
        border: 0;
        background-color: #fafafa;
        box-shadow: 0.1rem 0.1rem 0.1rem rgb(0 0 0 / 10%);
    }

    .login-modal {
        width: 90%;
        max-width: 400px;
        border: 0.2rem solid var(--bs-light);
        border-radius: 0.5rem;
        box-shadow: 0 0 0.5rem 0.5rem rgba(255, 255, 255, 0.1);
    }

    .login-modal::backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 400px) {
        .login-modal {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 0;
            border: none;
        }

        .actions {
            flex-direction: column-reverse;
        }

        .inputs {
            grid-template-columns: 1fr;
        }
    }

    .inputs .label {
        text-align: center;
    }
</style>
